import { useTranslation } from "react-i18next";
import { Card, CardContent } from "@/components/ui/card";
import {
  CheckCircle,
  Clock,
  AlertTriangle,
  RotateCcw,
  Loader,
  List,
} from "lucide-react";

interface MetricsSummaryProps {
  status: {
    PENDING: number;
    IN_PROGRESS: number;
    DONE: number;
    DONE_WITH_ISSUES: number;
    IN_REVIEW: number;
  };
  completenessLate: {
    totalLateTasks: number;
    completedOnTime: number;
  };
  total: number;
  setStatusFilter: (status: string | null) => void;
  setShowTable: (show: boolean) => void;
}

export function MetricsSummary({
  status,
  completenessLate,
  total,
  setStatusFilter,
  setShowTable,
}: MetricsSummaryProps) {
 

  if (!status) {
    console.log("Status is null or undefined");
    return null;
  }

  const { t } = useTranslation();

 

  const taskStats = [
    {
      title: t("metrics.summary.total"),
      value: total,
      icon: List,
      color: "bg-gray-100",
      hoverColor: "bg-gray-200",
      textColor: "text-gray-700",
      size: "small",
      status: null,
      change: 5,
    },
    {
      title: t("metrics.summary.pending"),
      value: status.PENDING,
      icon: Clock,
      color: "bg-[#6C788A]",
      hoverColor: "bg-[#6C788A]",
      textColor: "text-[#6C788A]",
      size: "large",
      status: "PENDING",
      change: -2,
    },
    {
      title: t("metrics.summary.in_progress"),
      value: status.IN_PROGRESS,
      icon: Loader,
      color: "bg-[#D8A232]",
      hoverColor: "bg-[#D8A232]",
      textColor: "text-[#D8A232]",
      size: "large",
      status: "IN_PROGRESS",
      change: 3,
    },
    {
      title: t("metrics.summary.completed"),
      value: status.DONE,
      icon: CheckCircle,
      color: "bg-[#6FC36D]",
      hoverColor: "bg-[#6FC36D]",
      textColor: "text-[#6FC36D]",
      size: "large",
      status: "DONE",
      change: 8,
    },
    {
      title: t("metrics.summary.in_review"),
      value: status.IN_REVIEW,
      icon: RotateCcw,
      color: "bg-[#3B82F6]",
      hoverColor: "bg-[#3B82F6]",
      textColor: "text-[#3B82F6]",
      size: "small",
      status: "IN_REVIEW",
      change: 0,
    },
    {
      title: t("metrics.summary.with_issues"),
      value: status.DONE_WITH_ISSUES,
      icon: AlertTriangle,
      color: "bg-[#E57A42]",
      hoverColor: "bg-[#E57A42]",
      textColor: "text-[#E57A42]",
      size: "small",
      status: "DONE_WITH_ISSUES",
      change: -1,
    },
    {
      title: t("metrics.summary.late"),
      value: completenessLate.totalLateTasks,
      icon: Clock,
      color: "bg-red-500",
      hoverColor: "bg-red-500",
      textColor: "text-red-500",
      size: "small",
      status: "LATE",
      change: -3,
    },
  ];

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {taskStats
          .filter((stat) => stat.size === "large")
          .map((stat, index) => (
            <Card
              key={index}
              className={`border-none shadow-sm transition-all duration-300 ease-in-out cursor-pointer
                        ${stat.color} bg-opacity-10 hover:bg-opacity-100 hover:shadow-md group`}
              onClick={() => {
                setStatusFilter(stat.status);
                setShowTable(true);
              }}
            >
              <CardContent className="p-6 flex items-start justify-between">
                <div>
                  <p
                    className={`text-sm font-medium ${stat.textColor} group-hover:text-white mb-1`}
                  >
                    {stat.title}
                  </p>
                  <p className="text-3xl font-bold text-gray-800 group-hover:text-white">
                    {stat.value}
                  </p>
                  <div
                    className={`flex items-center mt-2 text-gray-500 group-hover:text-white`}
                  ></div>
                </div>
                <stat.icon
                  className={`w-10 h-10 ${stat.textColor} group-hover:text-white opacity-80`}
                />
              </CardContent>
            </Card>
          ))}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {taskStats
          .filter((stat) => stat.size === "small")
          .map((stat, index) => (
            <Card
              key={index}
              className={`border-none shadow-sm transition-all duration-300 ease-in-out cursor-pointer
                        ${stat.color} bg-opacity-10 hover:bg-opacity-100 hover:shadow-md group`}
              onClick={() => {
                setStatusFilter(stat.status);
                setShowTable(true);
              }}
            >
              <CardContent className="p-4 flex items-center justify-between">
                <div>
                  <p
                    className={`text-xs font-medium ${stat.textColor} group-hover:text-white mb-1`}
                  >
                    {stat.title}
                  </p>
                  <p className="text-xl font-bold text-gray-800 group-hover:text-white">
                    {stat.value}
                  </p>
                  <div
                    className={`flex items-center mt-1 text-gray-500 group-hover:text-white`}
                  ></div>
                </div>
                <stat.icon
                  className={`w-8 h-8 ${stat.textColor} group-hover:text-white opacity-80`}
                />
              </CardContent>
            </Card>
          ))}
      </div>
    </div>
  );
}
