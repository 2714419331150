import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { Minimize2, Maximize2, SortAscIcon, SortDescIcon, RowsIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import useSWR from 'swr'
import { Loader } from "@/components/ui/loader"
import { startOfDay, endOfDay } from 'date-fns'
import { DateRange } from "react-day-picker"
import { fetcher } from '@/lib/fetcher'
import { ChartErrorBoundary } from "@/components/error/ChartErrorBoundary";

interface ChannelMetricsProps {
  id: string
  name: string
  total: number
  PENDING: number
  IN_PROGRESS: number
  DONE: number
  DONE_WITH_ISSUES: number
  IN_REVIEW: number
}

interface ChannelMetricsData {
  [channelId: string]: ChannelMetricsProps
}

const transformChannelData = (data: ChannelMetricsData | undefined, view: string, pageSize: number, sortOrder: 'asc' | 'desc' = 'desc') => {
  if (!data) return []
  
  
  
  return Object.entries(data)
    .map(([key, metrics]) => {
      return {
        channel: metrics.name,
        channelId: key,
        TOTAL: metrics.total,
        PENDING: metrics.PENDING,
        IN_PROGRESS: metrics.IN_PROGRESS,
        IN_REVIEW: metrics.IN_REVIEW,
        DONE_WITH_ISSUES: metrics.DONE_WITH_ISSUES,
        DONE: metrics.DONE
      };
    })
    .sort((a, b) => {
      const comparison = (b[view as keyof typeof a] as number) - (a[view as keyof typeof a] as number)
      return sortOrder === 'desc' ? comparison : -comparison
    })
    .slice(0, pageSize)
}

interface ChannelTasksProps {
  selectedProjects: string[]
  selectedPersons: string[]
  selectedRange?: DateRange
  onTaskSelect: (channelId: string, status?: string) => void
}

export function ChannelTasks({ selectedProjects, selectedPersons, selectedRange, onTaskSelect }: ChannelTasksProps) {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [currentView, setCurrentView] = useState('TOTAL')
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const cardRef = useRef<HTMLDivElement>(null)
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc')

  const {
    data: channelMetricsData,
    error,
    isLoading
  } = useSWR<{
    data: Record<string, ChannelMetricsProps>
    pagination: {
      total: number
      currentPage: number
      pageSize: number
      totalPages: number
      hasMore: boolean
    }
  }>(
    `/v1/metrics/channel-metrics${
      selectedProjects.length > 0 ||
      selectedPersons.length > 0 ||
      (selectedRange && (selectedRange.from || selectedRange.to))
        ? `?${new URLSearchParams({
            page: page.toString(),
            pageSize: pageSize.toString(),
            sort: sortOrder,
            sort_by: currentView.toLowerCase(),
            ...(selectedPersons.length > 0 && {
              people: JSON.stringify(selectedPersons),
            }),
            ...(selectedProjects.length > 0 && {
              projects: JSON.stringify(selectedProjects),
            }),
            ...(selectedRange &&
              selectedRange.from && {
                from: String(startOfDay(selectedRange.from).getTime()),
              }),
            ...(selectedRange &&
              selectedRange.to && {
                to: String(endOfDay(selectedRange.to).getTime()),
              }),
        }).toString()}`
        : `?page=${page}&pageSize=${pageSize}&sort=${sortOrder}`
    }`,
    fetcher
  )

  const handlePageSizeChange = (value: string) => {
    const newSize = parseInt(value)
    setPageSize(newSize)
    setPage(1)
  }

  const chartData = useMemo(() => {
    if (!channelMetricsData?.data) return []
    return transformChannelData(channelMetricsData.data, currentView, pageSize, sortOrder)
  }, [channelMetricsData?.data, currentView, pageSize, sortOrder])

  const viewOptions = [
    { key: 'TOTAL', label: t('metrics.charts.status.label'), color: '#8b5cf6' },
    { key: 'PENDING', label: t('metrics.charts.status.status.PENDING'), color: '#6C788A' },
    { key: 'IN_PROGRESS', label: t('metrics.charts.status.status.IN_PROGRESS'), color: '#D8A232' },
    { key: 'IN_REVIEW', label: t('metrics.charts.status.status.IN_REVIEW'), color: '#3B82F6' },
    { key: 'DONE_WITH_ISSUES', label: t('metrics.charts.status.status.DONE_WITH_ISSUES'), color: '#E57A42' },
    { key: 'DONE', label: t('metrics.charts.status.status.DONE'), color: '#6FC36D' }
  ]

  const toggleFullScreen = useCallback(async () => {
    try {
      if (!document.fullscreenElement) {
        await cardRef.current?.requestFullscreen()
      } else {
        await document.exitFullscreen()
      }
    } catch (err) {
      console.error('Fullscreen error:', err)
    }
  }, [])

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement)
    }

    document.addEventListener('fullscreenchange', handleFullScreenChange)
    return () => document.removeEventListener('fullscreenchange', handleFullScreenChange)
  }, [])

  

  const handleBarClick = useCallback((data: any) => {
    
    
    const channelId = data.id || data.channelId;
    const status = currentView === 'TOTAL' ? undefined : currentView;
    
    
    
    onTaskSelect(
        channelId,
        status
    );
  }, [currentView, onTaskSelect, channelMetricsData?.data]);

  if (isLoading) return <Loader className="h-6 w-6" />
  if (error) return <div>Error loading data</div>
  if (!channelMetricsData?.data) return null

  

  return (
    <div className="flex flex-col w-full">
      <div className="w-full mb-2 p-2 bg-gradient-to-r from-purple-100 to-purple-50 border border-purple-200 rounded-md shadow-sm">
        <div className="flex flex-wrap gap-2% items-center justify-start">
          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            <RowsIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            <Select
              value={pageSize.toString()}
              onValueChange={handlePageSizeChange}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.channels.selectRows')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                {[5, 10, 25, 50, 100].map((size) => (
                  <SelectItem key={size} value={size.toString()} className="text-[0.8em]">
                    {size} {t('metrics.charts.persons.rows')}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center gap-1% min-w-[140px] w-[49%]">
            {sortOrder === 'desc' ? (
              <SortDescIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            ) : (
              <SortAscIcon className="w-[8%] h-auto text-purple-600 flex-shrink-0" />
            )}
            <Select
              value={sortOrder}
              onValueChange={(value: 'desc' | 'asc') => setSortOrder(value)}
            >
              <SelectTrigger className="w-[91%] h-8 bg-white border-purple-200 hover:border-purple-300 focus:ring-1 focus:ring-purple-300 text-[0.8em]">
                <SelectValue placeholder={t('metrics.charts.channels.selectOrder')} />
              </SelectTrigger>
              <SelectContent 
                className="z-[9999]"
                position="popper" 
                sideOffset={4}
              >
                <SelectItem value="desc" className="text-[0.8em]">{t('metrics.charts.persons.mostTasks')}</SelectItem>
                <SelectItem value="asc" className="text-[0.8em]">{t('metrics.charts.persons.leastTasks')}</SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>

      <Card className="w-full max-w-3xl" ref={cardRef}>
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle>{t("metrics.charts.channels.title")}</CardTitle>
            <CardDescription>{t("metrics.charts.channels.subtitle")}</CardDescription>
          </div>
          <Button
            variant="outline"
            size="icon"
            onClick={toggleFullScreen}
            aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
          >
            {isFullScreen ? <Minimize2 className="h-4 w-4" /> : <Maximize2 className="h-4 w-4" />}
          </Button>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap gap-2 mb-4">
            {viewOptions.map((option) => (
              <Button
                key={option.key}
                variant={currentView === option.key ? "default" : "outline"}
                onClick={() => setCurrentView(option.key)}
                className="text-[clamp(0.6rem,1.2vw,0.875rem)] whitespace-nowrap"
                style={{
                  backgroundColor: currentView === option.key ? option.color : 'transparent',
                  color: currentView === option.key ? (option.key === 'TOTAL' ? 'white' : 'black') : option.color,
                  borderColor: option.color,
                  padding: '0 0.75rem',
                  flex: `${option.label.length} 0 auto`
                }}
              >
                {option.label}
              </Button>
            ))}
          </div>
          <div className={`relative overflow-hidden transition-all duration-300 ease-in-out ${isFullScreen ? 'h-[calc(100vh-200px)]' : 'h-[400px]'}`}>
            <ChartErrorBoundary>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={chartData}
                  layout="vertical"
                  margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
                  onClick={(e) => {
                    if (e && e.activePayload) {
                      handleBarClick(e.activePayload[0].payload);
                    }
                  }}
                >
                  <XAxis type="number"
                    tickFormatter={(value) => `${Math.round(value)}`} 
                    allowDecimals={false}
                  />
                  <YAxis 
                    dataKey="channel" 
                    type="category" 
                    width={80}
                    style={{ fontSize: '12px' }}
                  />
                  <Tooltip 
                    contentStyle={{ background: 'hsl(var(--card))', border: '1px solid hsl(var(--border))' }}
                    labelStyle={{ color: 'hsl(var(--foreground))' }}
                  />
                  <Bar 
                    dataKey={currentView}
                    fill={viewOptions.find(option => option.key === currentView)?.color} 
                    radius={[0, 4, 4, 0]} 
                  />
                </BarChart>
              </ResponsiveContainer>
            </ChartErrorBoundary>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

