import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { authedFetch, fetcher } from "@/lib/fetcher";
import { ChangeEvent, Key, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  KeyIcon,
  ListRestart,
  XIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { FetchError } from "@/components/error";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import { Users, Building2, ClipboardList, CheckSquare } from "lucide-react";
import useDebounceState from "@/hooks/debounce";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { LayoutDashboard } from "lucide-react";

function CreateToken({ done }: { done: () => void }) {
  const { toast } = useToast();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();

  async function action(data: any) {
    try {
      await authedFetch("/v1/admin/token/create", {
        method: "POST",
        body: JSON.stringify(data),
      });

      toast({ title: t("admin.token.create.toast") });

      done();
    } catch (e) {
      toast({ title: t("admin.token.create.toast_error") });
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="my-2" variant="outline">
          {t("admin.token.create.button")}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("admin.token.create.title")}</DialogTitle>
          <DialogDescription>
            {t("admin.token.create.description")}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(action)}>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="email" className="text-right">
                {t("admin.token.create.email")}
              </Label>
              <Input
                id="email"
                type="email"
                required
                className="col-span-3"
                {...register("email", { required: true })}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="note" className="text-right">
                {t("admin.token.create.note")}
              </Label>
              <Input
                id="note"
                required
                className="col-span-3"
                {...register("note", { required: true })}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="lang" className="text-right">
                {t("admin.token.create.lang")}
              </Label>
              <select {...register("lang", { required: true })}>
                <option value="es">es</option>
                <option value="en">en</option>
              </select>
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">{t("admin.token.create.submit")}</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}

function InvalidateToken({ id, done }: { id: string; done: () => void }) {
  const { toast } = useToast();
  const { t } = useTranslation();

  async function action() {
    const req = await authedFetch(`/v1/admin/token/invalidate/${id}`, {
      method: "DELETE",
    });

    if (req.ok) {
      toast({
        title: t("admin.token.invalidate.toast"),
      });

      done();
    } else {
      toast({ title: t("admin.token.invalidate.toast_error") });
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <button className="mx-1 border p-1 rounded-lg hover:bg-slate-100">
          <XIcon />
        </button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("admin.token.invalidate.title")}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("admin.token.invalidate.description")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>
            {t("admin.token.invalidate.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction onClick={action}>
            {t("admin.token.invalidate.action")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function Tokens() {
  const { t } = useTranslation();
  const { data, error, mutate } = useSWR("/v1/admin/token/all", fetcher);

  if (error) return <FetchError />;

  if (!data) {
    return (
      <div className="max-w-xl my-6">
        <Skeleton className="h-10 w-60 my-2" />
        <Skeleton className="h-8 w-52 my-2" />
        <div>
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-xl my-6">
      <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
        {t("admin.token.title")}
      </h2>
      <p className="leading-7 mb-2">{t("admin.token.description")}</p>
      <CreateToken done={mutate} />
      <div>
        {data &&
          data.data &&
          data.data.map(
            (
              i: { id: string; email: string; note: string | undefined },
              key: Key
            ) => (
              <div
                className="border p-4 flex justify-between items-center"
                key={key}
              >
                <div className="flex items-center justify-between gap-2">
                  <div>
                    <p className="font-bold">{i.email}</p>
                    {i.note && <p>{i.note}</p>}
                  </div>
                </div>
                <InvalidateToken id={i.id} done={mutate} />
              </div>
            )
          )}
      </div>
    </div>
  );
}

function Stats() {
  const { data: stats, error } = useSWR<{
    users: number;
    orgs: number;
    tasks: number;
    tasks_done: number;
  }>("/v1/admin/stats", fetcher);

  if (error) return <FetchError />;

  if (!stats) {
    return (
      <div className="py-6 space-y-6 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800">
          Pulpoo-wide Statistics
        </h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
          {[...Array(4)].map((_, i) => (
            <Card key={i}>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-4 w-4" />
              </CardHeader>
              <CardContent>
                <Skeleton className="h-8 w-16" />
              </CardContent>
            </Card>
          ))}
        </div>
        <Card>
          <CardHeader>
            <Skeleton className="h-6 w-48" />
          </CardHeader>
          <CardContent>
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-48 mt-2" />
          </CardContent>
        </Card>
      </div>
    );
  }

  const taskCompletionRate =
    stats.tasks > 0 ? (stats.tasks_done / stats.tasks) * 100 : 0;

  const formatNumber = (num: number) =>
    new Intl.NumberFormat(undefined, { notation: "compact" }).format(num);

  return (
    <div className="py-4 space-y-4 rounded-lg max-w-5xl">
      <h2 className="text-xl font-bold text-gray-800">
        Pulpoo-wide Statistics
      </h2>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1">
            <CardTitle className="text-sm font-medium">Total Users</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold">{formatNumber(stats.users)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1">
            <CardTitle className="text-sm font-medium">Organizations</CardTitle>
            <Building2 className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold">{formatNumber(stats.orgs)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1">
            <CardTitle className="text-sm font-medium">Total Tasks</CardTitle>
            <ClipboardList className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold">{formatNumber(stats.tasks)}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-1">
            <CardTitle className="text-sm font-medium">
              Completed Tasks
            </CardTitle>
            <CheckSquare className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-xl font-bold">
              {formatNumber(stats.tasks_done)}
            </div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader className="pb-2">
          <CardTitle className="text-sm font-medium">
            Task Completion Progress
          </CardTitle>
        </CardHeader>
        <CardContent>
          <Progress value={taskCompletionRate} className="w-full" />
          <p className="mt-1 text-sm text-muted-foreground">
            {formatNumber(stats.tasks_done)} out of {formatNumber(stats.tasks)}{" "}
            tasks completed ({taskCompletionRate.toFixed(1)}%)
          </p>
        </CardContent>
      </Card>
    </div>
  );
}

function OrganizationItem({ org, key }: { org: any; key: Key }) {
  const { t } = useTranslation();
  const { toast } = useToast();
  const ids = org.members.map((member: any) => member.id);
  const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");

  async function sendReport() {
    if (status === "loading") return;

    setStatus("loading");

    try {
      await Promise.all(
        ids.map((id: string) =>
          authedFetch(`/v1/metrics/reports/generate`, {
            method: "POST",
            body: JSON.stringify({
              time: new Date().toISOString(),
              user_id: id,
            }),
          })
        )
      );

      setStatus("success");
      toast({
        title: t("organizations.reports.success"),
      });
    } catch (e) {
      setStatus("error");
      toast({
        title: t("organizations.reports.error"),
        variant: "destructive",
      });
    }
  }

  return (
    <div key={key} className="border p-4 flex justify-between items-center">
      <div className="flex items-center justify-between gap-2">
        {org.photo && (
          <img src={org.photo} alt="" className="h-12 w-12 rounded-full border" />
        )}
        <div>
          <p className="font-bold">{org.name}</p>
          <p className="text-sm text-gray-500 line-clamp-1">{org.bio}</p>
          <p className="text-sm text-gray-500">{org._count.members} members</p>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <p className="text-sm text-gray-500">
          {new Date(org.created_at).toLocaleDateString()}
        </p>
        {org.is_activated ? (
          <span className="px-2 py-1 bg-green-100 text-green-800 rounded-full text-xs">
            Active
          </span>
        ) : (
          <span className="px-2 py-1 bg-red-100 text-red-800 rounded-full text-xs">
            Inactive
          </span>
        )}
        <Button onClick={sendReport} disabled={status === "loading" || status === "success"}>
          {status === "loading"
            ? t("organizations.reports.loading")
            : status === "success"
            ? t("organizations.reports.sent")
            : status === "error"
            ? t("organizations.reports.retry")
            : t("organizations.reports.send")}
        </Button>
      </div>
    </div>
  );
}

export function Organizations() {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [query, debouncedQuery, setQuery] = useDebounceState("", 800);

  const { data, mutate, error } = useSWR(
    `/v1/admin/orgs/explorer?page=${page}&q=${debouncedQuery}`,
    fetcher
  );

  if (error) return <FetchError />;

  if (!data) {
    return (
      <div className="max-w-2xl my-6">
        <Skeleton className="h-10 w-60 my-2" />
        <Skeleton className="h-8 w-52 my-2" />
        <div className="my-2 flex items-center">
          <Skeleton className="h-8 w-1/2 mr-1" />
          <Skeleton className="h-6 w-6 m-1" />
        </div>
        <div>
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
          <Skeleton className="h-16 w-full my-2" />
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl my-6">
      <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight">
        {t("organizations.explorer.title", { qt: data.total })}
      </h2>
      <p className="leading-7 mb-2">
        {t("organizations.explorer.description")}
      </p>
      <div className="my-2 flex items-center">
        <Input
          className="w-1/2"
          placeholder={t("users.explorer.placeholder")}
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setQuery(e.target.value);
          }}
        />
        <button
          className="mx-1 border p-1 rounded-lg hover:bg-slate-100"
          onClick={() => mutate()}
        >
          <ListRestart />
        </button>
      </div>
      <div>
        {data.organizations.map((org: any, key: Key) => (
          <OrganizationItem org={org} key={key} />
        ))}
      </div>
      <div className="flex items-center my-2">
        <button
          className="border p-1 rounded-l-lg hover:bg-slate-100 disabled:cursor-not-allowed disabled:hover:bg-slate-200 disabled:bg-slate-200"
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page <= 1}
        >
          <ChevronLeftIcon />
        </button>
        <button
          className="border p-1 rounded-r-lg hover:bg-slate-100 disabled:cursor-not-allowed disabled:hover:bg-slate-200 disabled:bg-slate-200"
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={page >= data.pages}
        >
          <ChevronRightIcon />
        </button>
        <p className="mx-2">
          {t("users.explorer.page", { page: page, pages: data.pages })}
        </p>
      </div>
    </div>
  );
}

export function SuperAdmin() {
  const { t } = useTranslation();

  return (
    <section className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="scroll-m-20 text-3xl font-semibold tracking-tight first:mt-0">
          {t("admin.title")}
        </h1>
      </div>

      <Tabs defaultValue="dashboard" className="space-y-4">
        <TabsList>
          <TabsTrigger value="dashboard" className="flex items-center gap-2">
            <LayoutDashboard className="h-4 w-4" />
            Dashboard
          </TabsTrigger>
          <TabsTrigger value="tokens" className="flex items-center gap-2">
            <KeyIcon className="h-4 w-4" />
            Tokens
          </TabsTrigger>
          <TabsTrigger
            value="organizations"
            className="flex items-center gap-2"
          >
            <Building2 className="h-4 w-4" />
            Organizations
          </TabsTrigger>
        </TabsList>

        <TabsContent value="dashboard" className="space-y-4">
          <Stats />
        </TabsContent>

        <TabsContent value="tokens" className="space-y-4">
          <Tokens />
        </TabsContent>

        <TabsContent value="organizations" className="space-y-4">
          <Organizations />
        </TabsContent>
      </Tabs>
    </section>
  );
}
